var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-phases"},[_c('div',{staticClass:"px-1 py-3 md-px-3"},[_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.phaseTableColumns,"rows":_vm.phaseTableData,"totalRows":_vm.phaseTablePagination.total,"isLoading":_vm.isLoading,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.openPhaseDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('button',{staticClass:"btn main bordered",on:{"click":function($event){return _vm.editPhase(props.row.id)}}},[_c('i',{staticClass:"fas fa-pen"})]),_c('button',{staticClass:"btn danger ml-1",on:{"click":function($event){return _vm.deletePhase(props.row)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no phases"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn main mr-2",on:{"click":_vm.addPhase}},[_c('i',{staticClass:"fas fa-plus"})])])])],1)]),_c('modal',{attrs:{"fullscreen":""},model:{value:(_vm.addPhaseModal.isShown),callback:function ($$v) {_vm.$set(_vm.addPhaseModal, "isShown", $$v)},expression:"addPhaseModal.isShown"}},[_c('add-phase',{staticClass:"card",attrs:{"type":_vm.type,"projectId":_vm.projectId,"developerId":_vm.developerId},on:{"cancel":function($event){_vm.addPhaseModal.isShown = false},"submit":_vm.createPhase}})],1),_c('modal',{attrs:{"fullscreen":""},model:{value:(_vm.editPhaseModal.isShown),callback:function ($$v) {_vm.$set(_vm.editPhaseModal, "isShown", $$v)},expression:"editPhaseModal.isShown"}},[_c('edit-phase',{staticClass:"card",attrs:{"type":_vm.type,"projectId":_vm.projectId,"developerId":_vm.developerId,"phaseId":_vm.editPhaseModal.phaseToEdit},on:{"cancel":function($event){_vm.editPhaseModal.isShown = false},"submit":_vm.updatePhase}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }