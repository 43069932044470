<template>
  <div class="project-phases">
    <!-- ========================== Table Listing ========================== -->
    <div class="px-1 py-3 md-px-3">
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="phaseTableColumns"
          :rows="phaseTableData"
          :totalRows="phaseTablePagination.total"
          :isLoading="isLoading"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="openPhaseDetail"
        >
          <div slot="loadingContent">
            <div class="d-flex justify-content-center">
              <spinner></spinner>
            </div>
          </div>
          <div slot="emptystate">
            <no-data message="There is no phases"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="addPhase">
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'" @click.stop>
              <button
                class="btn main bordered"
                @click="editPhase(props.row.id)"
              >
                <i class="fas fa-pen"></i>
              </button>
              <button class="btn danger ml-1" @click="deletePhase(props.row)">
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!-- ========================= Modal: Add New ========================== -->
    <modal v-model="addPhaseModal.isShown" fullscreen>
      <add-phase
        class="card"
        :type="type"
        :projectId="projectId"
        :developerId="developerId"
        @cancel="addPhaseModal.isShown = false"
        @submit="createPhase"
      ></add-phase>
    </modal>
    <!-- ========================== Modal: Edit ============================ -->
    <modal v-model="editPhaseModal.isShown" fullscreen>
      <edit-phase
        class="card"
        :type="type"
        :projectId="projectId"
        :developerId="developerId"
        :phaseId="editPhaseModal.phaseToEdit"
        @cancel="editPhaseModal.isShown = false"
        @submit="updatePhase"
      ></edit-phase>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectPhaseAPI from "@/modules/Project/api/projectPhase";

export default {
  components: {
    AddPhase: () => import("./AddPhase"),
    EditPhase: () => import("./EditPhase"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [vueGoodTable],
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    projectId: {
      required: true
    },
    developerId: {
      required: true
    }
  },
  data: function () {
    return {
      phaseAPI: new ProjectPhaseAPI(this.type),

      phaseTableData: [],
      phaseTablePagination: {},
      phaseTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "200px"
        },
        {
          label: "Name",
          field: "name"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      },

      isLoading: false,

      addPhaseModal: {
        isShown: false
      },
      editPhaseModal: {
        isShown: false,
        phaseToEdit: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllPhases();
    },
    addPhase() {
      this.addPhaseModal.isShown = true;
    },
    editPhase(id) {
      this.editPhaseModal.phaseToEdit = id;
      this.editPhaseModal.isShown = true;
    },

    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllPhases();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllPhases().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    openPhaseDetail(event) {
      this.currentPhaseDetail = event.row;
      this.isPhaseDetailShown = true;
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllPhases();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllPhases();
    },
    // ========================== API Related =========================
    async getAllPhases() {
      this.isLoading = true;
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.phaseAPI.getProjectPhases({
          queries: { ...filteredParam, "project:id": this.projectId },
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.phaseTableData = this._.cloneDeep(data.data);
        this.phaseTablePagination = this._.cloneDeep(data.meta.pagination);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get phases list. Please try again later"
        });
      }
    },
    async createPhase(phase) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.phaseAPI.createProjectPhase(phase);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Phase has been created successfully."
        });
        this.addPhaseModal.isShown = false;
        await this.getAllPhases();
        this.$store.dispatch("projectDetail/refreshPhases", this.projectId);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to create phase for the project. Please try again later."
        });
        console.error(error);
      }
    },
    async updatePhase(id, phase) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.phaseAPI.updateProjectPhase(id, phase);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Phase has been updated successfully."
        });
        this.editPhaseModal.isShown = false;
        await this.getAllPhases();
        this.$store.dispatch("projectDetail/refreshPhases", this.projectId);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update phase. Please try again later."
        });
        console.error(error);
      }
    },
    async deletePhase(phase) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Delete Phase",
        message: `Are you sure you want to delete phase "${phase.name}"? This action cannot be undone.`,
        confirmText: "Delete"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.phaseAPI.deleteProjectPhase(phase.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Phase has been deleted successfully."
          });
          this.editPhaseModal.isShown = false;
          await this.getAllPhases();
          this.$store.dispatch("projectDetail/refreshPhases", this.projectId);
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to delete phase."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
